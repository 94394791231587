import React from 'react'
import ProductTemplate from 'components/templates/ProductTemplate/ProductTemplate'

export default function SpecificPartWorkerTrainingPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const products = {
    0: {
      productId: 'SPWT',
      productName: 'Formazione lavoratore parte specifica',
      productImages: {
        0: ['courses/corso-formazione-lavoratore-parte-specifica.jpg', 'corso formazione lavoratore parte specifica'],
      },
      productSize: 'Un corso formazione',
      productWeight: 0,
      productPrice: '4000',
      price: 'price_1J0o5tLde8wm4I0oJUHO2hkd',
    },
  }

  const description =
    '<strong>OBBLIGATORIETÀ:</strong> Obbligatorio per tutti i lavoratori di qualsiasi tipologia di azienda del settore a rischio basso afferenti alle macrocategorie e corrispondenza ATECO 2002-2007. Il corso può essere seguito dai lavoratori già partecipanti alla formazione generale di 4 h. È rivolto anche alle aziende che all’interno del proprio organico hanno diversi tipi di rischio (alto, medio, basso): gli allievi possono partecipare alla parte specifica di 4 h e poi seguire separatamente l’ulteriore parte specifica a seconda del rischio di appartenenza.<br/><br/> <strong>DURATA:</strong> 4 h<br/>'

  const presentation = {
    title: 'Formazione lavoratore parte specifica',
    description:
      '<strong>COME SI SVOLGE:</strong> ll corso si svolge online in modalità e-learning. All’acquisto vengono inviati link e credenziali con i quali collegarsi in qualsiasi momento, anche in più volte, dal proprio PC o dallo smart phone. Alla fine del corso è previsto un test finale che può essere sostenuto più volte, superato il quale viene rilasciato regolare attestato di avvenuta formazione, accreditato da Regione, in conformità al D. Lgs. 81/08 ed all’Accordo Stato Regioni del 21 dicembre 2011, che definisce durata e contenuti minimi del corso.<br/><br/> <strong>DESCRIZIONE:</strong> Come previsto dall’Accordo Stato Regioni del 21.12.2011, gli argomenti trattati durante la formazione specifica si riferiscono ai rischi riferiti alle mansioni e ai possibili danni e alle conseguenti misure e procedure di prevenzione e protezione caratteristiche del settore di appartenenza dell’azienda.',
    photo: 'corso-formazione-generale-diagramma.jpg',
  }

  return (
    <>
      <ProductTemplate
        seoKeywords={seoKeywords}
        products={products}
        description={description}
        presentation={presentation}
      />
    </>
  )
}
